/*
 * @Author: BURN dkbon1120@outlook.com
 * @Date: 2023-03-19 11:09:17
 * @LastEditors: BURN dkbon1120@outlook.com
 * @LastEditTime: 2023-03-19 11:16:35
 * @FilePath: /wx-h5-shop/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
