/*
 * @Author: BURN dkbon1120@outlook.com
 * @Date: 2023-03-19 11:09:17
 * @LastEditors: BURN dkbon1120@outlook.com
 * @LastEditTime: 2023-03-22 22:46:43
 * @FilePath: /wx-h5-shop/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import VConsole from 'vconsole';
new VConsole({ theme: 'dark' });

createApp(App).use(store).use(router).mount('#app')
