import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-37fce30c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  style: {
    "margin": "16px"
  }
};
import { ref } from 'vue';
import { showSuccessToast, showFailToast } from 'vant';
export default {
  __name: 'HomeView',
  setup(__props) {
    const uphone = ref('');
    let uCode = '';
    getCode();
    function getCode() {
      // 非静默授权，第一次有弹框
      let code = '';
      var local = window.location.href; // 获取页面url
      var appid = 'wx788a957a855a1717';
      code = getUrlCode().code; // 截取code
      if (code == null || code === '') {
        // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
      } else {
        // 你自己的业务逻辑
        console.log("kkw-code:", code);
        // onSubmit(code);
        uCode = code;
      }
    }
    function getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      // this.winUrl = url
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    }
    function onSubmit() {
      // fetch('api/base/shopping/bindPhone', {
      fetch('https://shop.qichunzhineng.com/shopping/bindPhone', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          code: uCode,
          phone: uphone.value
        })
      }).then(response => response.json()).then(data => {
        console.log('Success:', data);
        const {
          code,
          message
        } = data;
        if (code == 401) {
          showFailToast(message);
        } else if (code == 200) {
          showSuccessToast(message);
        } else {
          showFailToast(message);
        }
      }).catch(err => {
        console.log("err:", err);
      });
    }
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_form, {
        onSubmit: onSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            modelValue: uphone.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => uphone.value = $event),
            name: "手机号",
            label: "手机号",
            placeholder: "手机号",
            rules: [{
              required: true,
              message: '请填写手机号'
            }]
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(" 提交 ")]),
          _: 1
        })])]),
        _: 1
      })]);
    };
  }
};